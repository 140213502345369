<template>
  <div>
    <Loading/>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import { mapGetters } from "vuex";
import urlApi from "@/apis/liff/v2/url";

export default {
  data() {
    return {
     accessToken: null,
     profile: null,
    }
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters("liffAuth", ["user"]),
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const urlCode = this.$route.params.url_code;

      try {
        const response = await urlApi.getUrl(urlCode);

        let shortUrl = response.data.data;

        // 如果有啟用需註冊才能訪問，且使用者未註冊，導向註冊頁面
        if (shortUrl.config.permissions.includes("registered") && this.user.registered_at === null) {
          this.$router.push({
            name: "LiffRegisterIndex",
            params: {
              orgCode: this.$route.params.org_code,
            }
          });
          return;
        }

        console.log('redirect to', shortUrl.redirect)
        let dest = new URL(shortUrl.redirect);

        Object.entries(this.$route.query).forEach(([key, value]) => {
          // 這是 LINE LIFF OAUTH 關鍵字，如果繼續帶會有重複的 code 跟 state
          // 導致接續 OAUTH 失敗
          if (!['state', 'code', 'liffClientId', 'liffRedirectUri'].includes(key)) {
            dest.searchParams.append(key, value)
          }
        });

        if (!this.$route.query.skip) {
          window.location.href  = dest.toString()
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "請確認網址是否正確", "error");
      }
    },
  }
}
</script>
